import {
  AiFillGithub,
  AiFillLinkedin,
  AiOutlineMail,
  AiFillCaretRight
} from "react-icons/ai"
import ccp from './images/CCP.png';
import saa from './images/SAA.png';
import cloud_server from './images/cloud-server.png'
import front from './images/div.png'
import api from './images/api.png'
import profile from './images/ProfilePic.png'
import resume from './Resume - Ziyad Tahlilkar.pdf'
import messenger from './images/logo.png'
import './App.css'

function App() {

  return (
    <div>
      <main className="px-10 radial-bg md:px-20 lg:px-40">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between text-black">
            <h1 className="font-burtons text-xl">ziyadtahlilkar</h1>
            <ul className="flex items-center">
              <li>
                <a
                  className="transition-colors duration-500
                  bg-orange-500
                  hover:bg-amber-400
                  hover:text-black
                  border-2
                  border-red-500
                  hover:border-red-500
                  hover:scale-125
                  text-white px-4 py-2 border-none rounded-md ml-8"
                  href={resume} target="_blank">
                  Resume
                </a>
              </li>
            </ul>
          </nav>
          <div className="text-center p-10 py-10">
            <h2 className="text-5xl py-2 font-medium text-orange-500 md:text-6xl">
              Ziyad Tahlilkar
            </h2>
            <h3 className="text-2xl py-2 text-black md:text-3xl">
              Software Engineer
            </h3>
            <p className="text-md py-5 leading-8 text-black max-w-xl mx-auto md:text-xl">
              Versatile Full-Stack Developer Building Seamless Digital Solutions with Code
            </p>
            <div className="mx-auto bg-gradient-to-b from-orange-500 rounded-full w-60 h-60 relative overflow-hidden my-10 md:h-96 md:w-96">
              <img src={profile}></img>
            </div>
            <div className="text-5xl flex justify-center gap-16 py-3 text-gray-600 dark:text-gray-400">
              <a href="https://github.com/ZiyadT" target="_blank"><AiFillGithub className="transition-all duration-500 ease-out hover:text-orange-500"/></a>
              <a href="https://www.linkedin.com/in/ziyadt/" target="_blank"><AiFillLinkedin className="transition-all duration-500 ease-out hover:text-orange-500"/></a>
              <a href="mailto: ziyadtahlilkar@hotmail.com"><AiOutlineMail className="transition-all duration-500 ease-out hover:text-orange-500"/></a>
            </div>
          </div>
        </section>
        <section>
          <div>
            <h3 className="text-3xl py-1">My Skills</h3>
            <p className="text-md py-2 leading-8">
            Since the beginning of my journey as a software engineer, I have worked with a plethora of different technologies
            and collaborated with talented people to create digital products
            for both business and consumer use.
            </p>
          </div>
          <div className="lg:flex gap-10">
            <div className="text-center shadow-lg p-10 rounded-xl my-10 bg-gray-400 flex-1">
              <img src={front} className="mx-auto w-1/5 h-auto"/>
              <h3 className="text-lg font-medium pt-8 pb-2 text-orange-200">
                Front-End Development
              </h3>
              <p className="py-2">
                Let me help elevate your user experience with cutting-edge front-end development expertise, where innovative design seamlessly merges with flawless functionality.
              </p>
              <h4 className="py-4 text-orange-200">Technologies I Use</h4>
              <p className="py-1">HTML</p>
              <p className="py-1">TailwindCSS</p>
              <p className="py-1">Bootstrap</p>
              <p className="py-1">React.js</p>
              <p className="py-1">Django</p>
            </div>
            <div className="text-center shadow-lg p-10 rounded-xl my-10 bg-gray-400 flex-1">
              <img src={cloud_server} className="mx-auto w-1/5 h-auto"/>
              <h3 className="text-lg font-medium pt-8 pb-2 text-orange-200">
                Cloud Services
              </h3>
              <p className="py-2">
                As an AWS Certified Cloud Practitioner, I can architect and implement cloud based solutions for almost any application.
              </p>
              <h4 className="py-4 text-orange-200">My Achievements</h4>
              <div className="flex justify-between">
                <div>
                  <a href="https://www.credly.com/badges/15856d77-7a8f-453b-8b4d-7ae889c659f1/public_url" target="_blank">
                    <img src={ccp} alt="AWS CCP" className="mx-auto w-2/3 h-auto"/>
                  </a>
                  <p className="mx-auto text-sm italic">Obtained April 30th, 2023</p>
                </div>
                <div>
                  <img src={saa} alt="AWS SAA" className="mx-auto w-2/3 h-auto opacity-40"/>
                  <p className="mx-auto text-sm italic">In progress</p>
                </div>
              </div>
            </div>
            <div className="text-center shadow-lg p-10 rounded-xl my-10 bg-gray-400 flex-1">
              <img src={api} className="mx-auto w-1/5 h-auto"/>
              <h3 className="text-lg font-medium pt-8 pb-2 text-orange-200">Back-End Development</h3>
              <p className="py-2">
                I'll power your digital platforms with robust back-end development solutions, ensuring stability, scalability, and seamless performance from the ground up.
              </p>
              <h4 className="py-4 text-orange-200">Tools I Use</h4>
              <p className="py-1">Node.js</p>
              <p className="py-1">Express.js</p>
              <p className="py-1">FastAPI</p>
              <p className="py-1">MongoDB</p>
              <p className="py-1">SQL</p>
            </div>
          </div>
        </section>
        <section className="py-10">
          <div className="mb-8">
            <h3 className="text-3xl">Projects</h3>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:grid-rows-2 sm:gap-4">
          <div className="text-center py-4 bg-gray-100 text-gray-900 p-2 rounded-md my-2">
              <div className="overlay-container">
                <div className="proj-image">
                  <div className="flex justify-center">
                    <img src={messenger} className="w-10 h-10 my-auto" />
                    <h1 className="text-3xl font-bold p-2">Meessenger</h1>
                  </div>
                  <p className="text-sm">
                    Full-featured real-time messaging application modeled after Facebook Messenger, 
                    designed to provide an intuitive and interactive communication platform
                  </p>
                </div>
                <div className="proj-text flex">
                  <a href="https://github.com/ZiyadT/Meessenger" target="_blank"><AiFillGithub className="text-6xl text-gray-500 mx-5 transition-all duration-300 ease-out hover:text-green-500" /></a>
                  <a href="https://meessenger-lmok.vercel.app/" target="_blank"><AiFillCaretRight className="text-6xl text-gray-500 mx-5 transition-all duration-300 ease-out hover:text-green-500" /></a>
                </div>
              </div>
            </div>
            <div className="text-center py-4 tendie p-2 rounded-md my-2">
              <div className="overlay-container">
                <div className="proj-image">
                  <h1 className="text-3xl font-bold p-2">Tendie.</h1>
                  <p className="text-sm">
                    Web app that displays historical and current stock data for any U.S security as well as recent news articles on the selected company. 
                    Pulls stock data from TwelveData and news data from NewsAPI. Deployed on EC2.
                  </p>
                </div>
                <div className="proj-text flex">
                  <a href="https://github.com/ZiyadT/Tendie" target="_blank"><AiFillGithub className="text-6xl text-white mx-5 transition-all duration-300 ease-out hover:text-green-500" /></a>
                  <a href="http://54.173.185.222/" target="_blank"><AiFillCaretRight className="text-6xl text-white mx-5 transition-all duration-300 ease-out hover:text-green-500" /></a>
                </div>
              </div>
            </div>
            <div className="text-center py-4 weather text-white p-2 rounded-md my-2">
              <div className="overlay-container">
                <div className="proj-image">
                  <h1 className="text-3xl font-bold p-2">WeatherWatch</h1>
                  <p className="text-sm">
                    Interactive weather app that displays weather data on any city/region or coordinate pair provided and displays them as cards on a dashboard. Pulls data from OpenWeather API.
                  </p>
                </div>
                <div className="proj-text">
                  <a href="https://github.com/ZiyadT/WeatherWatch" target="_blank"><AiFillGithub className="text-6xl text-white mx-5 transition-all duration-300 ease-out hover:text-green-500" /></a>
                </div>
              </div>
            </div>
            <div className="text-center py-4 workplace text-white font-cabin p-2 rounded-md my-2">
              <div className="overlay-container">
                <div className="proj-image">
                  <h1 className="text-3xl font-bold p-2">Workplace Management</h1>
                  <p className="text-sm">
                    Simple CRUD application for managing departments and employees within a workplace.
                  </p>
                </div>
                <div className="proj-text">
                  <a href="https://github.com/ZiyadT/CRUD-Workplace-Management" target="_blank"><AiFillGithub className="text-6xl text-white mx-5 transition-all duration-300 ease-out hover:text-green-500" /></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
